/* eslint-disable */
import axios from 'axios';
import { useQuery } from 'react-query';
import { USERINFO_URL } from '@configs';

export const useInfo = () => {
  const tenant = localStorage.getItem('tenant');
  return useQuery({
    queryKey: ['userinfo', tenant],
    queryFn: () => tenant ? axios.get(USERINFO_URL) : null
  });
};
