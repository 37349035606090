import { Helmet } from 'react-helmet';

export const Head = ({ title = '', description = '' }) => {
  return (
    <Helmet
      title={title || undefined}
      defaultTitle="Ubox"
    >
      <meta name="description" content={description} />
    </Helmet>
  );
};
