import { PageHeader } from 'antd';

import { Head } from '../Head';

export const ContentLayout = ({ children, title, extra, ...rest }) => {
  return (
    <>
      <Head title={title} />
      <div className="py-6">
        <div style={{ display: 'flex', alignItems: 'center', background: '#F5F5F5' }}>
          <PageHeader
            ghost={true}
            title={title}
            extra={extra}
            style={{ flex: 1 }}
            {...rest}
          />
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">{children}</div>
      </div>
    </>
  );
};
