import { configAxios } from '@configs/axios';

import { AppProvider, UserProvider } from './providers';
import { AppRoutes } from './routes';
import './App.less';

configAxios();

function App() {
  return (
    <AppProvider>
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </AppProvider>
  );
}

export default App;
