export const GROUP_ORDERING = 'ORDERING';
export const GROUP_CUSTOMER_CARE = 'CUSTOMER_CARE';

const API_HOST = process.env.REACT_APP_API_URL;
export const API_URL = `${API_HOST}/ibb/api/v1`;
export const CS_API_URL = `${API_HOST}/cs/v1`;
export const AUTH_URL = API_HOST + '/auth';
export const REFRESH_URL = API_HOST + '/auth/refresh';
export const USERINFO_URL = API_HOST + '/auth/user/info';
export const LOGOUT_URL = `${process.env.REACT_APP_AUTH_URL}/confirm_logout`

export const ROWS_PER_PAGE = 10;

// BREAD permission
export const PER_BROWSE = 'BROWSE';
export const PER_READ = 'READ';
export const PER_EDIT = 'EDIT';
export const PER_ADD = 'ADD';
export const PER_DELETE = 'DELETE';

export const MODULE_USER = 'USER';
export const MODULE_ACCOUNT = 'BUY_ACCOUNT';
export const MODULE_CONDITION = 'CONDITION';
export const MODULE_EPIC = 'TOPIC';
export const MODULE_SCRIPT = 'SCRIPT';
export const MODULE_CHANNEL = 'PLATFORM';
export const MODULE_QUESTION = 'SUPPLIER_REPLY';
export const MODULE_ANSWER = 'SYSTEM_RESPONSE';
export const MODULE_TAG = 'KEYWORD';
export const MODULE_BARGAIN = 'BARGAIN';
export const MODULE_ACTIVITY = 'ACTIVITY';
export const MODULE_CONFIG = 'SYSTEM_CONFIG';
export const MODULE_ORDER_STATUS = 'ORDER_STATUS';
export const MODULE_TENANT = 'TENANT';
export const MODULE_PROJECT = 'PROJECT';

export const ACTIVITY_TYPE_NEGOTIATE = 1;
export const ACTIVITY_TYPE_NEGOTIATE_PLUS = 2;
export const ACTIVITY_TYPE_NEGOTIATE_SELLER_FIRST = 3;

export const TENANT_SYSTEM = 'system';
