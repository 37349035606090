import { useRoutes } from 'react-router-dom';

import { privateRoutes } from './private';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const element = useRoutes([...privateRoutes, ...publicRoutes]);

  return <>{element}</>;
};
