import { useState } from 'react';
import { decodeToken, isExpired } from 'react-jwt';

export const useToken = () => {
  const getToken = () => {
    try {
      const userToken = localStorage.getItem('token');
      if (userToken) {
        const tokenParse = JSON.parse(userToken);
        const tokenDecoded = decodeToken(tokenParse?.accessToken);
        return { ...tokenParse, ...tokenDecoded };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [token, setToken] = useState(getToken());
  const [tenant, setTenant] = useState(localStorage.getItem('tenant'));

  const saveToken = (tokenJson) => {
    try {
      localStorage.setItem('token', JSON.stringify(tokenJson));
      setToken(tokenJson);
      if (!localStorage.getItem('tenant')) {
        const tokenDecoded = decodeToken(tokenJson?.accessToken);
        if (tokenDecoded.tenant) {
          saveTenant(tokenDecoded.tenant);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveTenant = (tenant) => {
    localStorage.setItem('tenant', tenant);
    setTenant(tenant);
  }

  const clearToken = () => {
    // Clear cookies
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    sessionStorage.clear();
    localStorage.clear();
  };

  const tokenIsExpired = () => isExpired(token?.accessToken);

  const refreshTokenIsExpired = () => isExpired(token?.refreshToken);

  return {
    token,
    setToken: saveToken,
    clearToken,
    tokenIsExpired,
    refreshTokenIsExpired,
    tenant,
    setTenant: saveTenant,
  };
};
