import axios from 'axios';
import qs from 'qs';
import { isExpired } from 'react-jwt';
import { REFRESH_URL, AUTH_URL } from '@configs';

export function configAxios() {
  axios.interceptors.request.use(async function (config) {
    if (
      config.url.indexOf('/auth/login') > 0 ||
      config.url.indexOf('/auth/refresh') > 0
    ) {
      return config;
    }
    const userToken = localStorage.getItem('token');
    // const userToken = JSON.stringify({accessToken: process.env.REACT_APP_TOKEN})
    const userTokenParse = JSON.parse(userToken);
    let
      accessToken = userTokenParse?.accessToken,
      tenant;

    try {
      const isTokenExpired = isExpired(accessToken);
      if (isTokenExpired) {
        accessToken = await refreshToken(userTokenParse?.refreshToken);
        localStorage.setItem(
          'token',
          JSON.stringify({ ...userTokenParse, accessToken })
        );
      }
      tenant = localStorage.getItem('tenant');
    } catch (error) {
      console.error(error);
      localStorage.removeItem('token');
      window.location.href = `${AUTH_URL}?redirect_uri=${window.location.origin}`;
    }
    config.headers = {
      ...config.headers,
      'Access-Control-Allow-Origin': '*',
      'X-tenant': tenant,
      Authorization: userToken ? `Bearer ${accessToken}` : '',
    };
    return config;
  });
}

async function refreshToken(token) {
  const response = await axios.request(REFRESH_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({
      jwt: token,
    }),
  });
  return response.data.accessToken;
}
