import * as Constants from '@configs';

export const getActivityTypeLabel = type => {
  switch (type) {
    case Constants.ACTIVITY_TYPE_NEGOTIATE:
      return 'Mặc cả';

    case Constants.ACTIVITY_TYPE_NEGOTIATE_PLUS:
      return 'Mặc cả thêm';

    case Constants.ACTIVITY_TYPE_NEGOTIATE_SELLER_FIRST:
      return 'NCC mặc cả trước';

    default:
      return 'Không';
  }
}

export const isNegoActivity = (type) =>
  [
    Constants.ACTIVITY_TYPE_NEGOTIATE,
    Constants.ACTIVITY_TYPE_NEGOTIATE_PLUS,
    Constants.ACTIVITY_TYPE_NEGOTIATE_SELLER_FIRST,
  ].includes(type);

export const selectSearch = (input, option) =>
  (option?.label ?? '')
    .toLowerCase()
    .indexOf(input.toLowerCase()) > -1

export const genSelectOptions = data =>
  (data || []).map((c) => ({
    value: c.id,
    label: c.name,
  }))
