import { Button, Col, Layout, Menu, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {
  LOGOUT_URL,
  MODULE_ACCOUNT,
  MODULE_ACTIVITY,
  MODULE_ANSWER,
  MODULE_BARGAIN,
  MODULE_CHANNEL,
  MODULE_CONDITION,
  MODULE_CONFIG,
  MODULE_EPIC,
  MODULE_ORDER_STATUS,
  MODULE_QUESTION,
  MODULE_SCRIPT,
  MODULE_TAG,
  MODULE_USER,
  MODULE_TENANT,
  MODULE_PROJECT,
  TENANT_SYSTEM,
} from '@configs';
import { Link } from 'react-router-dom';
import { useRoute } from 'hooks/useRoute';
import Logo from 'assets/images/logo.webp';
import { useToken } from 'hooks/useToken';
import { UserContext } from '@providers';

const { Content, Header, Sider } = Layout;

const menuItems = [
  {
    url: '/',
    label: 'Dashboard',
  },
  {
    url: '/questions',
    label: 'Trao đổi từ đối tác',
    module: MODULE_QUESTION,
  },
  {
    url: '/answers',
    label: 'Phản hồi từ hệ thống',
    module: MODULE_ANSWER,
  },
  {
    url: '/tags',
    label: 'Từ khóa',
    module: MODULE_TAG,
  },
  {
    url: '/transaction-statuses',
    label: 'Trạng thái đơn hàng',
    module: MODULE_ORDER_STATUS,
  },
  {
    url: '/activities',
    label: 'Hành động',
    module: MODULE_ACTIVITY,
  },
  {
    url: '/users',
    label: 'Tài khoản GDV',
    module: MODULE_ACCOUNT,
  },
  {
    url: '/admin-users',
    label: 'Tài khoản hệ thống',
    module: MODULE_USER,
  },
  {
    url: '/system-configs',
    label: 'Cấu hình hệ thống',
    module: MODULE_CONFIG,
  },
  {
    url: '/bargains',
    label: 'Cấu hình mặc cả',
    module: MODULE_BARGAIN,
  },
  {
    url: '/channels',
    label: 'Kênh trao đổi',
    module: MODULE_CHANNEL,
  },
  {
    url: '/epics',
    label: 'Chủ đề',
    module: MODULE_EPIC,
  },
  {
    url: '/conditions',
    label: 'Điều kiện',
    module: MODULE_CONDITION,
  },
  {
    url: '/scripts',
    label: 'Kịch bản',
    module: MODULE_SCRIPT,
  },
  {
    url: '/tscripts',
    label: 'Kịch bản',
    module: MODULE_ACTIVITY,
  },
  {
    url: '/tenants',
    label: 'Tenant',
    module: MODULE_TENANT,
  },
  {
    url: '/projects',
    label: 'Web mua hộ',
    module: MODULE_PROJECT,
  },
];

const Sidebar = () => {
  const path = useRoute();
  const { permissions, tenant, menuOpen, toggleMenu } = useContext(UserContext);
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(getItems());
  }, [permissions])

  const isTenantCustomer = tenant !== TENANT_SYSTEM;

  const getItems = () => menuItems
    .map((item) => {
      const
        userPermissions = permissions || {},
        allowed = !!userPermissions[item.module],
        systemRequire = (MODULE_TENANT === item.module || item.url === '/activities') ? !isTenantCustomer : true,
        onlyForCustomer = ['/tscripts'].includes(item.url);

      return {
        show: (!item.module ||
          (allowed && systemRequire && !onlyForCustomer) ||
          (allowed && onlyForCustomer && isTenantCustomer)
        ).toString(),
        key: item.url,
        label: (
          <Link to={item.url} style={{ width: '100%', textAlign: 'left' }}>
            {item.label}
          </Link>
        ),
      };
    })
    .filter((mn) => mn.show == 'true');

  return (
    <StickyContainer style={{ position: 'relative' }}>
      <Button
        onClick={toggleMenu}
        style={{ 
          padding: 4, 
          position: 'absolute', 
          zIndex: 9,
          top: 20,
          left: '100%',
          border: 'none',
          background: 'unset',
          color: 'unset'
        }}
      >
        {menuOpen
          ? <MenuFoldOutlined />
          : <MenuUnfoldOutlined />
        }
      </Button>
      <Sticky>
        {({ style }) => (
          <Sider
            width={menuOpen ? 250 : 0}
            style={{
              ...style,
              display: 'grid',
              zIndex: 1,
              minHeight: 'calc(100vh - 64px)',
            }}>
            <Menu
              items={items}
              style={{
                height: '100%',
                borderRight: 0,
                background: '#F5F5F5'
              }}
              selectedKeys={[path]}
            />
          </Sider>)}
      </Sticky>
    </StickyContainer>
  );
};

export const MainLayout = ({ children }) => {
  const { clearToken, token } = useToken();
  const { tenant, menuOpen } = useContext(UserContext);
  const [contentWidth, setContentWidth] = useState('calc(100% - 250px)')

  useEffect(() => {
    setContentWidth(`calc(100% - ${menuOpen ? 250 : 0}px`)
  }, [menuOpen])


  const logout = () => {
    clearToken();
    window.location.href = `${LOGOUT_URL}?redirect_uri=${window.location.origin}`;
    return 'Hệ thống đang chuyển đến trang đăng nhập. Vui lòng chờ trong giây lát...';
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header>
        <Row align="middle" justify="space-between">
          <Col span={4}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Logo} height={42} />
              <span style={{ color: '#fcba37', fontStyle: 'italic' }}>{tenant}</span>
            </div>
          </Col>
          <Col>
            <div
              style={{ display: 'flex', alignItems: 'center', color: '#FFF' }}
            >
              Chào {token?.fullName} ({token?.username}),
              <Button
                type="link"
                style={{ float: 'right', color: '#FFF' }}
                onClick={logout}
              >
                Đăng xuất
              </Button>
            </div>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sidebar />
        <Content
          style={{
            maxWidth: contentWidth,
            marginLeft: 'auto',
            background: '#FFF',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
