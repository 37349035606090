import { Button, Spin } from 'antd';
import { lazyImport } from '@utils/lazyImport';
import { Suspense } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { useToken } from 'hooks/useToken';

import Error404Image from '../assets/images/oops-404-error.jpg';

const { AuthenticationRoutes } = lazyImport(
  () => import('@features/authentication'),
  'AuthenticationRoutes'
);

const App = () => {
  const { token } = useToken();
  if (token) {
    return <Navigate to="/" replace />;
  }
  return (
    <Suspense
      fallback={
        <div className="h-full w-full flex items-center justify-center">
          <Spin size="large" />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <App />,
    children: [{ path: '/authentication', element: <AuthenticationRoutes /> }],
  },
  {
    path: '*',
    element: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={Error404Image} />
        <Link to={'/'}>Về trang chủ</Link>
      </div>
    ),
  }
];
