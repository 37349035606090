import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useInfo } from 'features/authentication/api';
import { useToken } from 'hooks/useToken';
import { useDisclosure } from 'hooks/useDisclosure';
import { AppStorage } from 'helpers';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const { tenant, setTenant } = useToken();
  const mutation = useInfo();
  const [permissions, setPermissions] = useState(null);
  const { isOpen, open, close } = useDisclosure(AppStorage.getUISettings('menuOpen', true));

  useEffect(() => {
    if (mutation.isSuccess && mutation.data) {
      const perms = getPermissions(mutation?.data?.data?.permissions);
      setPermissions(perms)
      const tn = mutation?.data?.data?.tenant;
      setTenant(tn);
    }
  }, [mutation.isSuccess])

  const fetchUserInfo = () => {
    mutation.refetch();
  }

  const getPermissions = useCallback((userPerms) => {
    const result = {};
    if (Array.isArray(userPerms)) {
      userPerms.forEach((perm) => {
        const [_, __, moduleName, action] = perm.split(':');
        if (moduleName) {
          if (!result[moduleName]) {
            result[moduleName] = [action];
          } else {
            result[moduleName].push(action);
          }
        }
      });
    }
    return result;
  }, [])

  const toggleMenu = () => {
    AppStorage.setUISettings('menuOpen', !isOpen);
    isOpen ? close() : open();
  }

  return (
    <UserContext.Provider
      value={{
        permissions,
        tenant,
        fetchUserInfo,
        toggleMenu,
        menuOpen: isOpen,
      }}>
      {children}
    </UserContext.Provider>
  );
};
