export function set(key, value) {
  try {
    localStorage[key] = JSON.stringify(value);
  } catch (error) {
    localStorage[key] = value;
  }
  return localStorage[key]
}

export function get(key, defaultValue) {
  const value = localStorage[key];
  if (value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }
  return defaultValue;
}

export function clear() {
  return localStorage.clear()
}

export function remove(key) {
  return localStorage.removeItem(key)
}

// UI Settings
export function setUISettings(key, value) {
  const settings = get('UISettings', {});
  return set('UISettings', { ...settings, [key]: value });
}

export function getUISettings(key, defaultValue) {
  const settings = get('UISettings', {});
  const value = settings[key];
  return typeof value === 'undefined' && typeof defaultValue !== 'undefined' ? defaultValue : value;
}
